import React from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";

import Table from "../common/Table";
import ToggleModal from "../common/ToggleModal";
import {
  GeneralStateUpdaterNew,
  TableLoader,
} from "../../utils/Table";
import PieChart from "./PieChart";
import { DynamicActionBtn } from "../common/RenderCustomView";
import moment from "moment";
import { API_MERCHANT_ROOT } from "../../helper/constant";

const merchantSettlementColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    
  },
  {
    dataField: "broker",
    text: "broker"
  },
  {
    dataField: "rate",
    text: "rate"
  },
  {
    dataField: "totalReceived",
    text: "totalReceived"
  },
  {
    dataField: "status",
    text: "status"
  },
  {
    dataField: "amount",
    text: "amount"
  },
  {
    dataField: "createdAt",
    text: "createdAt"
  },
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      if (cell)
        return cell.map((e) => {
          if (e.modalName) return <ToggleModal {...e} />;
          return e;
        });
    },
  },
];

class MSettlementOrder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      settlement: { data: [], totalRecords:0, loading: false },
      pageSize: 10,
      currentPage: 0,
      statusChart: { data: [], loading: false },
      brokerChart: { data: [], loading: false },
      searchParams: { to: "", from: "", status: "", broker: "" },
    };


    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    this.getDataByBroker();
    this.getDataByStatus();

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    var yyyy = today.getFullYear();
    var options = { year: 'numeric', month: '2-digit', day: '2-digit',hour12: false,  hour: '2-digit', minute: '2-digit', second: '2-digit' };
    today = yyyy + '-' + mm + '-' + dd;
  
    var toDate = new Date(today + 'T23:59:00'); // Set "to" date to today at 23:59
  
    var fromDate = new Date(toDate); // Create a copy of "to" date
    fromDate.setDate(fromDate.getDate() - 7); // Subtract 7 days from the "from" date
  
    var fd = String(fromDate.getDate()).padStart(2, '0');
    var fm = String(fromDate.getMonth() + 1).padStart(2, '0'); // January is 0!
    var fy = fromDate.getFullYear();
  
    var fromdate = fy + '-' + fm + '-' + fd;
  
    const searchParams = { ...this.state.searchParams };
    searchParams["to"] = moment(toDate.toLocaleString('en-US', options)).format("YYYY-MM-DD HH:mm:ss");
    searchParams["from"] = fromdate + ' 00:00:00'; // Set "from" date to YYYY-MM-DD 00:00:00 format

    this.setState({ searchParams },()=> this.getMerchantSettlementOrder(0));
  }

  handleChange = ({ currentTarget: input }) => { 
    // console.log(input);
    const searchParams = { ...this.state.searchParams };
    searchParams[input.name] = input.value;

    this.setState({ searchParams });
  };

  handlePage = (page) => {
    this.setState({ currentPage: page });
    this.getMerchantSettlementOrder(page);
  }

  handleSearch = () => {
    this.getMerchantSettlementOrder(0);
  }

  getMerchantSettlementOrder(page) {
    
    const {to, from, status, broker} = this.state.searchParams;
    let params = {
        "page": page,
        "limit": this.state.pageSize
      };

      if(to != '') { params.to = moment(to).toISOString(); }
      if(from != '') {  params.from = moment(from).toISOString(); }
      if(status != '') { params.status = status;}
      if(broker != '') { params.broker = broker; }

    // console.log(params);
    this.generalStateUpdater("settlement", {
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/settlement-order/get-all-settlement`,
      method: "POST",
      data: params,
      postProcessor: (e) => {
        const View = (
          <DynamicActionBtn
            link={`/merchant-admin-settlement-order-detail/${e.uniqueId}`}
            type="navigateBtn"
            options={{
              variant: "info",
              name: "View",
              data: e,
            }}
            />
        )

        return {
          ...e,
          actions: [
            View
          ],
        };
      },
    });
  }

  getDataByStatus() {
    this.generalStateUpdater("statusChart", {
      method: "POST",
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/settlement-order/get-stats-by-status`,
    });
    
  }

  getDataByBroker() {
    this.generalStateUpdater("brokerChart", {
      method: "POST",
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/settlement-order/get-stats-by-broker`,
    });
  }

  renderTableData(name) {
    const { currentPage, pageSize } =  this.state;
    let srNo = currentPage * pageSize;
    if (this.state[name].loading === true) return TableLoader("broker");
    return {
      data: this.state[name].data.map((e, i) => {
        if(e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss');
        }
        if(e.amount) {
          e.amount = e.amount.toLocaleString();
        }
        return {
          srNo: i + 1 + srNo,
          ...e,
        };
      }),
    };
  }

  render() {
    return (
      <div className="kyb main-panel">
        <Card className="custom-card-1">
          
          <div className="card-body">
            <Row>
             <Col lg={6} >
             <div className="title">
                Settlement Status
                <div className="title--bottom"></div>
              </div>
              <Col ol md={12} sm={12}>
    
            <table style={{ borderRadius: "6px" }} className="table table-bordered table-hover table-striped" border="1" cellPadding="10" cellSpacing="0">
                <thead style={{ borderTopLeftRadius: "1rem", borderTopRightRadius: "1rem" }} className="thead-dark">
                  <tr>
                    <th>Status</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                  
                {this.state.statusChart.data.length > 0 ?
                  <>
                    {this.state.statusChart.data.map((item, index) => (
                      <tr key={index}>
                        <td>{item.status}</td>
                        <td>{item.count}</td>
                      </tr>
                    ))}
                  </>
                  :
                  <tr>
                    <td colSpan={2} className="text-center">
                      No Data
                    </td>
                  </tr>
                }
                </tbody>
              </table>
                
              </Col>
              </Col>
              <Col lg={6}>
              <div className="title">
                Broker Distribution
                <div className="title--bottom"></div>
              </div>
              <Col ol md={12} sm={12}>

              <table style={{ borderRadius: "6px" }} className="table table-bordered table-hover table-striped" border="1" cellPadding="10" cellSpacing="0">
                <thead style={{ borderTopLeftRadius: "1rem", borderTopRightRadius: "1rem" }} className="thead-dark">
                  <tr>
                    <th>Broker</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.brokerChart.data.length > 0 ?
                  <>
                    {this.state.brokerChart.data.map((item, index) => (
                      <tr key={index}>
                        <td>{item.broker}</td>
                        <td>{item.count}</td>
                      </tr>
                    ))}
                  </>
                  :
                  <tr>
                    <td colSpan={2} className="text-center">
                      No Data
                    </td>
                  </tr>
                }
                </tbody>
              </table>
              </Col>
              </Col>
            </Row>
          </div>
        </Card>
        <Card className="custom-card-1">
          <div className="title">
            Merchant: Settlement Order
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
            <Card className="custom-card-1">
                <div className="card-body">
                  <Row>
                   <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>From:</Form.Label>
                        <Form.Control type="datetime-local" value={this.state.searchParams.from} name="from" onChange={this.handleChange} />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>To:</Form.Label>
                        <Form.Control type="datetime-local" value={this.state.searchParams.to} name="to" onChange={this.handleChange} />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Status:</Form.Label>
                        <select className="form-control" name="status" onChange={this.handleChange}>
                          <option value="">select</option>
                          <option value="pending">Pending</option>
                          <option value="error">Error</option>
                          <option value="completed">Completed</option>
                        </select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Broker:</Form.Label>
                        <Form.Control type="text" value={this.state.searchParams.broker} name="broker" onChange={this.handleChange} />
                      </Form.Group>
                    </Col>

                    <Col style={{ marginTop: "25px"}}>
                      <Button variant="primary" type="button" onClick={()=>this.handleSearch()}>
                        Search
                      </Button>
                    </Col>   
                    
                  </Row>
                </div>
            </Card>

            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={merchantSettlementColumn}
                  className="table1"
                  tableName="Merchant Settlement Order"
                  keyField="srNo"
                  customPagination="custom"
                  totalCount={this.state.settlement.totalRecords}
                  pageSize={this.state.pageSize}
                  pageNumber={this.state.currentPage}
                  onPageChange={this.handlePage}                
                  {...this.renderTableData("settlement")}
                />
              </Col>
            </Row>

            <br />
          </div>
        </Card>
      </div>
    );
  }
}

export default MSettlementOrder;

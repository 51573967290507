import React, { Component } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { MerchantAxiosInstance } from "../../helper/AxiosInstance";
import { AddNoti } from "../../helper/Notification";
import { ParseError } from "../../helper/ResponseHelper";

class MerchantCurrency extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            settlementCurrencyList: [],
            options: [],
            submitRequest: false,
            toggleActionDetails: null,
        };
    }

    componentDidMount() {
        this.getSettlementCurrency(true);
    }

    componentDidUpdate(_prevProps, prevState) {
      if (prevState.toggleActionDetails !== this.state.toggleActionDetails) {
          const { toggleActionDetails } = this.state;
          if (toggleActionDetails) {
              if (toggleActionDetails.action === "enable") {
                  this.enableCurrencyAction();
              } else if (toggleActionDetails.action === "disable") {
                  this.disableCurrencyAction();
              }
          }
      }
    }

    getSettlementCurrency = (loading) => {
        if (loading) {
            this.setState({ isLoading: true });
        }

        const merchanCurrPayload = {
            merchantKeyId: this.props.merchantKeyDetail.uniqueId,
        };

        let promises = [
            MerchantAxiosInstance.post(
                "/admin-dashboard-api-service/merchant-key/get-all-merchant-currency",
                merchanCurrPayload
            ),
            MerchantAxiosInstance.post(
                "/admin-dashboard-api-service/payment-currency/get-all-payment-currency"
            ),
        ];

        Promise.allSettled(promises)
            .then((res) => {
                let [currencyList, paymentCurrencyList] = res;
                currencyList = currencyList.value;
                paymentCurrencyList = paymentCurrencyList.value;

                if (
                    currencyList &&
                    (currencyList.status === 200 || currencyList.status === 201)
                ) {
                    currencyList = currencyList.data.data;
                }

                if (
                    paymentCurrencyList &&
                    (paymentCurrencyList.status === 200 ||
                        paymentCurrencyList.status === 201)
                ) {
                    const currencyOptions = paymentCurrencyList.data.data
                        .filter((ele) => ele.status === true)
                        .map((ele) => {
                            const currType = ele.currencyType;
                            let itemExistsPayload = {};

                            if (currencyList.length > 0) {
                                currencyList.forEach((item) => {
                                    const merchantCurrType = item.currencyType;
                                    const compareString = `${ele.currencyId}?${currType}`;
                                    if (
                                        compareString ===
                                        `${item.currencyId}?${item.currencyType}`
                                    ) {
                                        itemExistsPayload = {
                                            ...ele,
                                            [merchantCurrType === "blockchain"
                                                ? "merchantBlockchainCurrencyId"
                                                : "merchantWalletCurrencyId"]:
                                                item.uniqueId,
                                            [merchantCurrType === "blockchain"
                                                ? "merchantBlockchainStatus"
                                                : "merchantWalletStatus"]:
                                                item.status,
                                            merchantCurrencyType:
                                                ele.currencyType,
                                            [currType === "blockchain"
                                                ? "blockChainValue"
                                                : "walletValue"]: `${ele.currencyId}?${currType}`,
                                            [currType === "blockchain"
                                                ? "isBlockchainAdded"
                                                : "isWalletAdded"]: true,
                                        };
                                    }
                                });
                            }

                            return Object.keys(itemExistsPayload).length > 0
                                ? itemExistsPayload
                                : {
                                      ...ele,
                                      [currType === "blockchain"
                                          ? "blockChainValue"
                                          : "walletValue"]: `${ele.currencyId}?${currType}`,
                                      [currType === "blockchain"
                                          ? "isBlockchainAdded"
                                          : "isWalletAdded"]: false,
                                  };
                        });

                    let paymentCurrencyListMergedArray = [
                        ...new Set(
                            currencyOptions.map((ele) => ele.currencyName)
                        ),
                    ];
                    let paymentListingArray =
                        paymentCurrencyListMergedArray.map((pymListItem) => {
                            const pymFilteredList = currencyOptions.filter(
                                (list) => list.currencyName === pymListItem
                            );

                            if (pymFilteredList.length === 2) {
                                return {
                                    ...pymFilteredList[0],
                                    ...pymFilteredList[1],
                                };
                            }

                            return pymFilteredList[0];
                        });

                    this.setState({
                        settlementCurrencyList: paymentListingArray,
                        options: paymentListingArray,
                    });
                }
            })
            .catch((err) => {
                console.error("Error fetching settlement currency: ", err);
            })
            .finally(() => {
                if (loading) {
                    this.setState({ isLoading: false });
                }
            });
    };

    hanldleToggleSubmitCurrency = (item) => {
        const { merchantKeyDetail } = this.props;
        this.setState({ submitRequest: true });

        const { walletValue = null, blockChainValue = null } = item;

        let walletPayload = {};
        let blockchainPayload = {};
        let promises = [];

        if (blockChainValue) {
            let [blockchaincurrencyId, blockchaincurrencyType] =
                blockChainValue.split("?");
            blockchainPayload = {
                merchantKeyId: merchantKeyDetail.uniqueId,
                currencyId: blockchaincurrencyId,
                currencyType: blockchaincurrencyType,
            };
            promises.push(
                MerchantAxiosInstance.post(
                    "/admin-dashboard-api-service/merchant-key/add-merchant-currency",
                    blockchainPayload
                )
            );
        }

        if (walletValue) {
            let [walletcurrencyId, walletcurrencyType] = walletValue.split("?");
            walletPayload = {
                merchantKeyId: merchantKeyDetail.uniqueId,
                currencyId: walletcurrencyId,
                currencyType: walletcurrencyType,
            };
            promises.push(
                MerchantAxiosInstance.post(
                    "/admin-dashboard-api-service/merchant-key/add-merchant-currency",
                    walletPayload
                )
            );
        }

        Promise.allSettled(promises)
            .then((res) => {
                let blockchainRequest, walletRequest, currencyRequest;

                if (promises.length === 2) {
                    [blockchainRequest, walletRequest] = res;
                    let errorMessage = null;
                    if (
                        blockchainRequest.status === "rejected" ||
                        walletRequest.status === "rejected"
                    ) {
                        if (blockchainRequest.status === "rejected") {
                            errorMessage = blockchainRequest.reason.message;
                        } else {
                            errorMessage = walletRequest.reason.message;
                        }
                    }
                    if (errorMessage) {
                        throw new Error(errorMessage);
                    }

                    blockchainRequest =
                        blockchainRequest.status === "fulfilled"
                            ? blockchainRequest.value
                            : null;
                    walletRequest =
                        walletRequest.status === "fulfilled"
                            ? walletRequest.value
                            : null;

                    if (blockchainRequest && walletRequest) {
                        AddNoti("Currency added successfully", {
                            type: "success",
                        });
                        this.getSettlementCurrency(false);
                    }
                } else {
                    [currencyRequest] = res;
                    let errorMessage =
                        currencyRequest.status === "rejected"
                            ? currencyRequest.reason.message
                            : null;
                    if (errorMessage) {
                        throw new Error(errorMessage);
                    }
                    currencyRequest =
                        currencyRequest.status === "fulfilled"
                            ? currencyRequest.value
                            : null;

                    if (currencyRequest) {
                        AddNoti("Currency added successfully", {
                            type: "success",
                        });
                        this.getSettlementCurrency(false);
                    }
                }
            })
            .catch((err) => {
                if (err && err.response && err.response.data) {
                    AddNoti(ParseError(err.response.data.error.message), {
                        type: "error",
                    });
                } else if (err) {
                    AddNoti(ParseError(err), {
                        type: "error",
                    });
                } else {
                    AddNoti(ParseError("Something went wrong. Try again."), {
                        type: "error",
                    });
                }
            })
            .finally(() => {
                this.setState({ submitRequest: false });
            });
    };

    renderToggleSwitch = (item, type) => {
        const { submitRequest } = this.state;
        const value =
            type === "blockchain" ? item.walletValue : item.blockChainValue;
        const renderToggle =
            type === "blockchain" ? item.isBlockchainAdded : item.isWalletAdded;

        if (renderToggle) {
            let currencyStatus = false;

            currencyStatus =
                type === "blockchain"
                    ? item.merchantBlockchainStatus
                    : item.merchantWalletStatus;
            const toggleHandler = currencyStatus
                ? () =>
                      this.disableCurrency(
                          type === "blockchain"
                              ? item.merchantBlockchainCurrencyId
                              : item.merchantWalletCurrencyId,
                          type
                      )
                : () =>
                      this.enableCurrency(
                          type === "blockchain"
                              ? item.merchantBlockchainCurrencyId
                              : item.merchantWalletCurrencyId,
                          type
                      );
            return (
                <Form.Check
                    type='switch'
                    id={`currency-switch-value-${item.currencyName}-${value}`}
                    defaultChecked={currencyStatus}
                    onChange={() => toggleHandler()}
                    disabled={submitRequest}
                />
            );
        }

        return <></>;
    };

    renderAddCurrency = (item) => {
        if (!(item.isBlockchainAdded || item.isWalletAdded)) {
            return (
                <Button
                    size='sm'
                    onClick={() => this.hanldleToggleSubmitCurrency(item)}
                >
                    Add
                </Button>
            );
        }

        return <></>;
    };

    enableCurrency = (id, type) => {
        this.onUpdateCurrencyStatusHandler(id, type, "enable");
    };

    disableCurrency = (id, type) => {
        this.onUpdateCurrencyStatusHandler(id, type, "disable");
    };

    onUpdateCurrencyStatusHandler = (
        id,
        currType,
        action,
        revertAction = false
    ) => {
        const { settlementCurrencyList } = this.state;

        const updateListing = settlementCurrencyList.map((curr) => {
            let itemExistsPayload = {};
            const merchantCurrType = currType;

            if (currType === merchantCurrType) {
                let findKeyValue =
                    merchantCurrType === "blockchain"
                        ? curr.merchantBlockchainCurrencyId
                        : curr.merchantWalletCurrencyId;

                if (`${id}?${currType}` === `${findKeyValue}?${currType}`) {
                    itemExistsPayload = {
                        ...curr,
                        [merchantCurrType === "blockchain"
                            ? "merchantBlockchainStatus"
                            : "merchantWalletStatus"]:
                            merchantCurrType === "blockchain"
                                ? !curr.merchantBlockchainStatus
                                : !curr.merchantWalletStatus,
                    };
                }
            }

            return Object.keys(itemExistsPayload).length > 0
                ? itemExistsPayload
                : curr;
        });

        this.setState({
            settlementCurrencyList: updateListing,
            options: updateListing,
        });

        if (!revertAction) {
            this.setState({
                toggleActionDetails: {
                    action,
                    id,
                    type: currType,
                },
            });
        }
    };

    enableCurrency = (id, type) => {
        this.onUpdateCurrencyStatusHandler(id, type, "enable");
    };

    disableCurrency = (id, type) => {
        this.onUpdateCurrencyStatusHandler(id, type, "disable");
    };

    enableCurrencyAction = () => {
        this.setState({ submitRequest: true });

        const { id } = this.state.toggleActionDetails;
        const payload = { uniqueId: id };

        MerchantAxiosInstance.post(
            "/admin-dashboard-api-service/merchant-key/enable-merchant-currency",
            payload
        )
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    this.setState({ toggleActionDetails: null });
                }
            })
            .catch((err) => {
                const errorMessage =
                    err && err.response && err.response.data
                        ? `${err.response.data.error.message}`
                        : "Something went wrong try again";
                AddNoti(ParseError(errorMessage), { type: "error" });

                const { id, type, action } = this.state.toggleActionDetails;
                this.onUpdateCurrencyStatusHandler(id, type, action, true);
            })
            .finally(() => {
                this.setState({ submitRequest: false });
            });
    };

    disableCurrencyAction = () => {
        this.setState({ submitRequest: true });

        const { id } = this.state.toggleActionDetails;
        const payload = { uniqueId: id };

        MerchantAxiosInstance.post(
            "/admin-dashboard-api-service/merchant-key/disable-merchant-currency",
            payload
        )
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    this.setState({ toggleActionDetails: null });
                }
            })
            .catch((err) => {
                const errorMessage =
                    err && err.response && err.response.data
                        ? `${err.response.data.error.message}`
                        : "Something went wrong try again";
                AddNoti(ParseError(errorMessage), { type: "error" });

                const { id, type, action } = this.state.toggleActionDetails;
                this.onUpdateCurrencyStatusHandler(id, type, action, true);
            })
            .finally(() => {
                this.setState({ submitRequest: false });
            });
    };

    render() {
        const { isLoading, options, settlementCurrencyList } = this.state;

        return (
            <div>
                {!isLoading && settlementCurrencyList.length > 0 ? (
                    <div className='add-merchant-currency listing-section custom-card p-0 mx-2 tokens_listing'>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Currency name</th>
                                    <th className='text-center'>Blockchain</th>
                                    <th className='text-center'>Wallet</th>
                                    <th className='text-center'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {options.map((item, index) => (
                                    <tr key={`${item.currencyName}-${index}`}>
                                        <td>{item.currencyName}</td>
                                        <td className='text-center'>
                                            {this.renderToggleSwitch(
                                                item,
                                                "blockchain"
                                            )}
                                        </td>
                                        <td className='text-center'>
                                            {this.renderToggleSwitch(
                                                item,
                                                "wallet"
                                            )}
                                        </td>
                                        <td className='text-center'>
                                            {this.renderAddCurrency(item)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                ) : (
                    <div className='custom-card p-0 mt-3 listing-section merchant-standalone-cards'>
                        <div className='listing-section--wrapper p-0 border-0'>
                            <div className='body card-body p-0'>
                                {isLoading ? (
                                    <div className='h-vh-50'>Loading...</div>
                                ) : (
                                    <p className='text-center m-2'>
                                        No Currencies Found
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default MerchantCurrency;

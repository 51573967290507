import React from "react";
import {  Card, Col, Row, Form } from "react-bootstrap";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import {
  textFilter,
  dateFilter,
  numberFilter,
} from "react-bootstrap-table2-filter";
import { DynamicActionBtn } from "../common/RenderCustomView";
import Table from "../common/Table";
import ToggleModal from "../common/ToggleModal";
import {
  GeneralStateUpdaterNew,
  TableLoader,
} from "../../utils/Table";
import { API_MERCHANT_ROOT } from "../../helper/constant";

const merchantColumns = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
  },
  {
    dataField: "merchantUser.email",
    text: "MerchantEmail",
  },
  {
    dataField: "labelName",
    text: "labelName",
  },
  {
    dataField: "callbackUrl",
    text: "callbackUrl",
  },
  {
    dataField: "tolerance",
    text: "tolerance",
  },
  {
    dataField: "autoSettle",
    text: "autoSettle",
  },
  {
    dataField: "settlementCurrencyName",
    text: "settlementCurrencyName",
  },
  {
    dataField: "apiKey",
    text: "apiKey",
  },
  {
    dataField: "isActive",
    text: "isActive",
  },
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      if (cell)
        return cell.map((e) => {
          if (e.modalName) return <ToggleModal {...e} />;
          return e;
        });
    },
  },
];

class MerchantKeyTable extends React.Component {
	constructor(props) {
    super(props);

    this.state = {
      keyDetail: { data: this.props.merchantKeyDetail, loading: false },
      merchantDetail: { data: [], loading: false },
    };

    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() { 
     this.getMerchantKeys();
   }

  getMerchantKeys() {
    this.generalStateUpdater("merchantDetail", {
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service//merchant-key/get-merchant-key-by-id`,
      method: "POST",
      data: {"merchantKeyId":this.state.keyDetail.data.uniqueId},
      postProcessor: (e) => {
        const Enable = (
          <DynamicActionBtn
            api={`${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/enable-merchant-key`}
            type="actionBtnMerchant"
            options={{
              variant: "warning",
              name: "Enable Key",
              data: { merchantKeyId: e.uniqueId },
            }}
          />
        )

        const Disable = (
          <DynamicActionBtn
            api={`${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/disable-merchant-key`}
            type="actionBtnMerchant"
            options={{
              variant: "danger",
              name: "Disable Key",
              data: { merchantKeyId: e.uniqueId },
            }}
          />
        )

        const Suspend = (
          <DynamicActionBtn
            api={`${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/suspend-merchant-key`}
            type="actionBtnMerchant"
            options={{
              variant: "danger",
              name: "Suspend",
              data: { merchantKeyId: e.uniqueId },
            }}
          />
        )

        const Unsuspend = (
          <DynamicActionBtn
            api={`${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/un-suspend-merchant-key`}
            type="actionBtnMerchant"
            options={{
              variant: "danger",
              name: "Unsuspend",
              data: { merchantKeyId: e.uniqueId },
            }}
          />
        )

  
        return {
          ...e,
          actions: [
            Enable,
            Disable,
            Suspend,
            Unsuspend
          ],
        };
      },
    });
  }
  

  renderTableData(name) { 
    if (this.state["merchantDetail"].loading === true) return TableLoader("srNo");
    let detail = [];
    if(this.state.merchantDetail.data && this.state.merchantDetail.data.length > 0) {
      detail = this.state.merchantDetail.data; 
    } 

    const enableFiatAPI = `${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/enable-fiat-buy`;
    const disableFiatAPI = `${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/disable-fiat-buy`;
    return {
      
      data: detail.map((e, i) => {
        const Enable = (
          <DynamicActionBtn
            api={`${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/enable-merchant-key`}
            type="actionBtnMerchant"
            options={{
              variant: "warning",
              name: "Enable Key",
              data: { merchantKeyId: e.uniqueId },
            }}
          />
        )

        const Disable = (
          <DynamicActionBtn
            api={`${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/disable-merchant-key`}
            type="actionBtnMerchant"
            options={{
              variant: "danger",
              name: "Disable Key",
              data: { merchantKeyId: e.uniqueId },
            }}
          />
        )

        const Suspend = (
          <DynamicActionBtn
            api={`${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/suspend-merchant-key`}
            type="actionBtnMerchant"
            options={{
              variant: "primary",
              name: "Suspend",
              data: { merchantKeyId: e.uniqueId },
            }}
          />
        )

        const Unsuspend = (
          <DynamicActionBtn
            api={`${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/un-suspend-merchant-key`}
            type="actionBtnMerchant"
            options={{
              variant: "danger",
              name: "Unsuspend",
              data: { merchantKeyId: e.uniqueId },
              className:"m-1"
            }}
          />
        )

        const EnableFIAT = (
          <DynamicActionBtn
            type="actionBtnMerchant"
            api={enableFiatAPI}
            options={{
              variant: "success",
              name: "Enable FIAT",
              data: {
                merchantKeyId: e.uniqueId,
              },
            }}
          />
        );

        const DisableFIAT = (
          <DynamicActionBtn
            type="actionBtnMerchant"
            api={disableFiatAPI}
            options={{
              variant: "danger",
              name: "Disable FIAT",
              data: {
                merchantKeyId: e.uniqueId,
              },
            }}
          />
        );

        return {
          srNo: i + 1,
          ...e,
          actions: [
            Enable,
            Disable,
            Suspend,
            Unsuspend,
            EnableFIAT,
            DisableFIAT
          ],
        };
      }),
    };
  }

  render() {
    return (
            <>
            <Row style={{ marginTop: "20px"}}>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={merchantColumns}
                  className="table1"
                  tableName="Merchant Key Detail"
                  keyField="srNo"
                  onRefresh={this.getMerchantKeys.bind(this)}
                  {...this.renderTableData("merchantDetail")}
                />
              </Col>
            </Row>
            </>
    );
  }
}

export default MerchantKeyTable;

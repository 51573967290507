import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import {
  MerchantAxiosInstance
} from "../../helper/AxiosInstance";
import { AddNoti } from "../../helper/Notification";
import { ParseError } from "../../helper/ResponseHelper";
import QRCode from "qrcode.react";
import { GeneralObjectViewer } from "./ObjectComponent";
import { useHistory } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import ReactSelect from 'react-select';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Select } from "antd";

export const ExpandableQRCode = ({ value, btnName = "Show QR" }) => {
  const [show, setshow] = useState(false);
  

  return (
    <div className="expandable-qr-code">
      <div className="input-view">
        <div className="input-view__label">{value}</div>
        <div className="input-view__btn">
          <Button onClick={() => setshow(!show)}>{btnName}</Button>
        </div>
      </div>
      <div>{show ? <QRCode value={value} /> : <></>}</div>
    </div>
  );
};

const RenderObject = ({ field, value }) => (
  <Row>
    <Col lg={4} md={4} sm={12}>
      <label className="form-control">{field}</label>
    </Col>
    <Col lg={8} md={8} sm={12}>
      {GeneralObjectViewer(value)}
    </Col>
  </Row>
);

const RenderCustomView = ({ label, type = "text", value, view }) => {
  let customView = null;
  switch (type) {
    case "text":
    case "number":
      customView = <div className="form-control">{value}</div>;
      break;
    case "textarea":
      customView = <div className="form-control textarea-view">{value}</div>;
      break;
    case "ckEditor":
      customView = <CKEditor
                      editor={ClassicEditor}
                      data={value}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                      }}
                  />
    break;  
    case "qr-code":
      customView = <ExpandableQRCode value={value} />;
      break;

    case "object":
      customView = <RenderObject field={label} value={value} />;
      break;
    case "image":
      customView = <img src={value} height="100px" />;
      break;
    default:
      return <></>;
  }

  if(view === 'vertical'){
    return (
      <>
      <Col lg={4} md={4} sm={12}>
        <label className="form-control">{label}</label>
      </Col>
      <Col lg={8} md={8} sm={12}>
        {customView}
      </Col>
      </>
    )
  }else{
    return (
      <Col md={6}>
        <Row>
        <Col lg={6} md={6} sm={12}>
        <label className="form-control">{label}</label>
        </Col>
        <Col lg={6} md={6} sm={12}>
        {customView}
        </Col>
        </Row>
      </Col>
    );
  }
 
};

const RenderInput = ({ field, ...inputProps }) => (
  <Row>
    <Col lg={4} md={4} sm={12}>
      <label className="form-control">{field}</label>
    </Col>
    <Col lg={8} md={8} sm={12}>
      <input className="form-control" {...inputProps} />
    </Col>
  </Row>
);
/**
 *
 * !FIX: DOB & File input types
 */
export const RenderInputCustomType = ({
  field,
  onChange: cb,
  type = "text",
  value,
  setInput,
  onBlur = () => {},
  formData,
  setFormData,
  CustomElement = <></>,
  formValues,
  label,
  view,
  category,
  appendElement = null,
  className="",
  ...inputProps
}) => {
  const [checked, setChecked] = React.useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [tags, setTags] = useState([]);
  let customInput = null;
  switch (type) {
    case "text":
    case "number":
      customInput = (
        <input
          type={type}
          name={field}
          className={`form-control ${className}`}
          onChange={(e) => {
            cb(e.target.value);
          }}
          value={value}
          onBlur={(e) => onBlur(e, { setInput, formData, setFormData })}
          {...inputProps}
        />
      );
      break;
   case "tagsinput":
    customInput = (<TagsInput
        value={tags.length > 0 ? tags : value ? value.split(',') : []}
        //onChange={setTags} 
        onChange={(e) => {
          setTags(e);
          cb(e);
        }}
        name="tags"
        placeHolder="Enter tags"
        onBlur={(e) => onBlur(e, { setInput, formData, setFormData })}
          {...inputProps}
      />);
      break;    
    case "textarea":
      customInput = (
        <textarea
          // type={type}
          className={`form-control ${className}`}
          onChange={(e) => {
            cb(e.target.value);
          }}
          // value={value}
          onBlur={(e) => onBlur(e, { setInput, formData, setFormData })}
          {...inputProps}
        />
      );
      break;
    case "select":
      customInput = (
        <select
          className={`form-control input-select ${className}`}
          onChange={(e) => {
            cb(e.target.value);
          }}
          value={value}
        >
          {inputProps.options.map(({ label, value: curVal, ...optionProps}, i) => (
            <option key={i + 1} value={curVal} {...optionProps}>
              {label}
            </option>
          ))}
        </select>
      );
      break;
      case "multiselect":
        customInput = (
          <select
            className={`form-control input-select ${className}`}
            multiple={true}
            onChange={(e) => {
              cb(e.target.options);
            }}
            value={value}
          >
            {inputProps.options.map(({ label, value: curVal, ...optionProps}, i) => (
              <option key={i + 1} value={curVal} {...optionProps}>
                {label}
              </option>
            ))}
          </select>
        );
        break;  
      case "multiselect-2":
        customInput = <Select               
            className={`form-control input-select ${className}`}
            value={value?value.split(","):[]} 
            onChange={(arr) => {
              cb(arr.join(","))}
            } 
              mode="multiple" 
              placeholder="Please select" 
              allowClear>
              {inputProps.options.map(({ label, value: curVal, ...optionProps}, i) => {
                return <Select.Option key={i + 1} value={curVal} {...optionProps}>
                {label}
            </Select.Option>
              })}
            </Select>
        break;    
    case "radio":
      // <div className="input-dynamic-form__input-radio" key={i + 1}></div>
      customInput = (
        <>
          {inputProps.options.map(({ value: curVal, label }, i) => (
            <div className="input-dynamic-form" key={i + 1} style={{ display: 'flex', flexDirection: 'none'}}>
              <Form.Label>
                <input
                  type={type}
                  value={curVal}
                  name={field}
                  key={i + 1}
                  onClick={(e) => {
                    cb(e.target.value);
                  }}
                  defaultChecked={curVal === value}
                />
                {label}
              </Form.Label>
            </div>
          ))}
        </>
      );
      break;
    case "checkbox":
      customInput = (
        <input
          className={`input-dynamic-form__input-checkbox ${className}`}
          type={type}
          value={checked}
          name={field}
          onChange={(e) => {
            setChecked(!checked);
            cb(e.target.checked);
          }}
          defaultChecked={checked}
          {...inputProps}
        />
      );
      break;
    case "qr-code":
      customInput = <ExpandableQRCode value={value} />;
      break;
    case "custom":
      customInput = (
        <CustomElement formValues={formValues} value={value} cb={cb} {...inputProps} />
      );
      break;
    case "date":
      customInput = (
        <input type="date" className={`form-control ${className}`} name={field} value={value} defaultValue={value} onChange={(e) => {
          cb(e.target.value);
        }} />
      );
      break;
      case "datetime":
      customInput = (
        <input type="datetime-local" className={`form-control ${className}`} name={field} value={value} defaultValue={value} onChange={(e) => {
          cb(e.target.value);
        }} />
      );
      break;
      case "datetime-2":
      customInput = (
        <input type="datetime-local" className={`form-control ${className}`} name={field} onChange={(e) => {
          const localDatetime = new Date(e.target.value);
          const utcISOString = localDatetime.toISOString();
      
          cb(utcISOString);
        }} />
      );
      break;
      case "react-select":
        customInput = <ReactSelect defaultValue={value} options={inputProps.options} onChange={(e) => {
          cb(e);
        }} />;
      break;
    case "file":
      customInput = (
        <>
        <input
          type={type}
          onChange={e => { cb(e.target); }}
          {...inputProps}
        />
        </>
      );
      break;
    default:
      return <></>;
  }
  if(view === 'horizontal'){
    return (
      <Col md={6}>
      <Row>
        <Col lg={6} md={6} sm={12}>
          <label className="form-control">{field}</label>
        </Col>
        <Col lg={6} md={6} sm={12}>
          { appendElement !== null ?
            <div className="d-flex align-items-center justify-content-between">
              {customInput}
              {appendElement}
            </div>
          :
            <>
              {customInput}
            </>
          }
        </Col>
      </Row>
      </Col>
    );    
  }else{
    return (
      <Row>
        <Col lg={4} md={4} sm={12}>
          <label className="form-control">{field}</label>
        </Col>
        <Col lg={8} md={8} sm={12}>
        { appendElement !== null ?
            <div className="d-flex align-items-center justify-content-between">
              {customInput}
              {appendElement}
            </div>
          :
            <>
              {customInput}
            </>
          }
        </Col>
      </Row>
    );
    
  }
};

const PostApi = (path, data, cb) => {
  MerchantAxiosInstance.post(path, { ...data })
    .then((resp) => {
      // if (resp.data.statusCode === 200 || resp.data.statusCode === 201) {
      if (resp.status === 200 || resp.status === 201) {
        AddNoti(resp.data.message, { type: "info" });
      } else {
        AddNoti(ParseError(resp), { type: "error" });
      }
    })
    .catch((e) => {
      // AddNoti(ParseError(e), { type: "error" });
      if(e.response) {
        if(e.response.data) {
          if(e.response.data.error) {
            AddNoti(e.response.data.error.field + ": " + e.response.data.error.message, { type: "error" });
          }
          else {
            AddNoti(ParseError(e), { type: "error" });
          }
        }
        else {
          AddNoti(ParseError(e), { type: "error" });
        }
      } else {
        AddNoti(ParseError(e), { type: "error" });
      }
    })
    .finally(() => {
      cb();
    });
};
const PostApiMerchant = (path, data, cb) => {
  MerchantAxiosInstance.post(path, { ...data })
    .then((resp) => { 
      if (resp.status === 200 || resp.status === 201) {
        AddNoti(resp.data.message, { type: "info" });
      } else { 
        AddNoti(ParseError(resp), { type: "error" });
      }
    })
    .catch((e) => { 
      if(e.response) {
        if(e.response.data) {
          if(e.response.data.error) {
            AddNoti(e.response.data.error.field + ": " + e.response.data.error.message, { type: "error" });
          }
          else {
            AddNoti(ParseError(e), { type: "error" });
          }
        }
        else {
          AddNoti(ParseError(e), { type: "error" });
        }
      } else {
        AddNoti(ParseError(e), { type: "error" });
      }
    })
    .finally(() => {
      cb();
    });
};

const PostApiUser = (path, data, cb) => {
  MerchantAxiosInstance.post(path, { ...data })
    .then((resp) => {
      if (resp.data.statusCode === 200 || resp.data.statusCode === 201) {
        AddNoti(resp.data.message, { type: "info" });
      } else {
        AddNoti(ParseError(resp), { type: "error" });
      }
    })
    .catch((e) => {
      if(e.response) {
        if(e.response.data) {
          if(e.response.data.error) {
            AddNoti(e.response.data.error.field + ": " + e.response.data.error.message, { type: "error" });
          }
          else {
            AddNoti(ParseError(e), { type: "error" });
          }
        }
        else {
          AddNoti(ParseError(e), { type: "error" });
        }
      } else {
        AddNoti(ParseError(e), { type: "error" });
      }
    })
    .finally(() => {
      cb();
    });
};

export const TableForm = (props) => {
  useEffect(() => {
    setInputs(props.data);
  }, [props.data]);

  const [inputs, setInputs] = useState(
    props.data.map(({ value = "", defaultValue = "" }) => {
      if (value !== "") {
        return value;
      } else if (defaultValue !== "") {
        return defaultValue;
      }
      return "";
    })
  );
  const [loading, setLoading] = useState(false);
  const setInput = (i, v) => setInputs(Object.assign([...inputs], { [i]: v }));
  return (
    <Container className="custom-input-1 table-form">
      {props.data.map(({ name, ...rest }, i) => (
        <RenderInput
          field={name}
          value={inputs[i]}
          onChange={(e) => setInput(i, e.target.value)}
          {...rest}
        />
      ))}
      <Button
        onClick={() => {
          setLoading(true);
          PostApi(
            props.api,
            props.data.reduce((acc, { name }, i) => {
              const query = {};
              query[name] = inputs[i];
              return { ...acc, ...query };
            }, {}),
            () => setLoading(false)
          );
        }}
        variant="primary"
        style={{ width: "10rem", float: "right" }}
        disabled={loading}
      >
        Submit
      </Button>
    </Container>
  );
};

export const DynamicViewForm = ({ data, view="vertical" }) => {
  return (
    <div className="custom-input-1">
      <Container>
      <Row>
        {data.map((e) => {
          return (
            <RenderCustomView label={e.name} value={e.value} type={e.type} view={view}/>            
          );
        })}
        </Row>
      </Container>
    </div>
  );
};

/**
 *
 * @param {*} props class properties
 */
export const DynamicForm = (props) => {
  const [inputs, setInputs] = useState(
    props.data.map(({ value = "", defaultValue = "" }) => {
      if (!["",null, undefined].includes(value) ) {
        return value;
      } else if (!["",null, undefined].includes(defaultValue)) {
        return defaultValue;
      }
      return "";
    })
  );

  const [loading, setLoading] = useState(false);
  const [formData, setFormDatas] = useState(props.data);
  const _postApi = props.isUserApi ? PostApiUser : PostApi;

  useEffect(() => {
    setFormDatas(props.data);
  }, [props.data]);

  const setInput = (i, v) => setInputs(Object.assign([...inputs], { [i]: v }));
  const setFormData = (i, v) =>
    setFormDatas(Object.assign([...formData], { [i]: v }));

  return (
    <div className={`custom-input-1 dynamic-form ${props.view === 'horizontal' ? '': 'container'}`}>
      {props.view === 'horizontal' ? (
        <Row>
        {formData.map(({ name, type, ...rest }, i) => (
          <RenderInputCustomType
            field={name}
            type={type}
            value={inputs[i]}
            key={i}
            setInput={(i, e) => setInput(i, e)}
            onChange={(e) => setInput(i, e)}
            formData={formData}
            formValues={inputs}
            setFormData={setFormData}
            view={props.view}
            {...rest}
          />
        ))}
        </Row>
      ): (
        
        formData.map(({ name, type, ...rest }, i) => (
          <RenderInputCustomType
            field={name}
            type={type}
            value={inputs[i]}
            key={i}
            setInput={(i, e) => setInput(i, e)}
            onChange={(e) => setInput(i, e)}
            formData={formData}
            formValues={inputs}
            setFormData={setFormData}
            view="vertical"
            {...rest}
          />
        ))
       
      )}
     
      <Button
        onClick={() => {
          setLoading(true);
          _postApi(
            props.api,
            props.data.reduce((acc, { name }, i) => {
              const query = {};
              const updatedV = typeof inputs[i] === "object" ? inputs[i].value ?? null : inputs[i];
              query[name] = updatedV;
              return { ...acc, ...query };
            }, {}),
            () => {
              if (props.cb && typeof props.cb === "function") props.cb();
              setLoading(false);
            }
          );
        }}
        variant="primary"
        style={{ width: "10rem", float: "right" }}
        disabled={loading}
      >
        Submit
      </Button>
    </div>
  );
};

/**
 *
 * demo start
 */

// export const RenderInputCustomType = ({
//   field,
//   onChange: cb,
//   type = "text",
//   value,
//   setInput,
//   onBlur = () => { },
//   formData,
//   setFormData,
//   ...inputProps
// }) => {
//   let customInput = null;
//   switch (type) {
//     case "text": case "number":
//       customInput = (
//         <input
//           type={type}
//           className="form-control"
//           onChange={(e) => {
//             cb(e.target.value);
//           }}
//           value={value}
//           onBlur={(e) => onBlur(e, { setInput, formData, setFormData })}
//           {...inputProps}
//         />
//       );
//       break;
//     case "select":
//       customInput = (
//         <select
//           className="form-control"
//           onChange={(e) => { cb(e.target.value); }}
//           value={value}
//         >
//           {inputProps.options.map(({ label, value: curVal }, i) => (
//             <option key={i + 1} value={curVal}>
//               {label}
//             </option>
//           ))}
//         </select>
//       );
//       break;
//     case "radio":
//       customInput = (
//         <>
//           {inputProps.options.map(({ value: curVal, label }, i) => (
//             <div className="input-dynamic-form__input-radio" key={i + 1}>
//               <Form.Label>
//                 <input
//                   type={type}
//                   value={curVal}
//                   name={field}
//                   key={i + 1}
//                   onClick={(e) => {
//                     cb(e.target.value);
//                   }}
//                   defaultChecked={curVal === value}
//                 />
//                 {label}
//               </Form.Label>
//             </div>
//           ))}
//         </>
//       );
//       break;
//     // case "date":
//     //   customInput = (
//     //     <input
//     //       className="form-control"
//     //       type={type}
//     //       value={value}
//     //       max={inputProps.maxDate || ""}
//     //       min={inputProps.minDate || ""}
//     //       onChange={(e) => {
//     //         console.log("CB", e);
//     //       }}
//     //     />
//     //   );
//     //   break;
//     // case "file":
//     //   customInput = (
//     //     <input
//     //       type={type}
//     //       accept="image/png, image/jpeg"
//     //       //  onChange={e => { cb(index, e.target.value); }}
//     //     />
//     //   );
//     //   break;
//     default:
//       return <></>;
//   }
//   return (
//     <Row>
//       <Col lg={4} md={4} sm={12}>
//         <label className="form-control">{field}</label>
//       </Col>
//       <Col lg={8} md={8} sm={12}>
//         {customInput}
//       </Col>
//     </Row>
//   );
// };
/**
 * demo stop
 */
export const SelectOption = [{ label: "Select", value: null, hidden: true }];

export const DynamicActionBtn = (props) => { 
  const [loading, setLoading] = useState(false);
  const history = useHistory(false);
  let { cb } = props;

  let customBtn = null;
  switch (props.type) {
    case "actionBtn":
      customBtn = (
        <Button
          variant={props.options.variant}
          className={props.options.className}
          onClick={() => {
            setLoading(false);
            PostApi(props.api, props.options.data, () => {
              setLoading(true)
              cb && cb();
            });
          }}
        >
          {props.options.name}
        </Button>
      );
      break;
    case "actionBtnMerchant":
        customBtn = (
          <Button
            variant={props.options.variant}
            className={props.options.className}
            onClick={() => {
              setLoading(false);
              PostApiMerchant(props.api, props.options.data, () => setLoading(true));
            }}
          >
            {props.options.name}
          </Button>
        );
        break;  
    case "navigateBtn":
      customBtn = (
        <Button
          variant={props.options.variant}
          className={props.options.className}
          onClick={() => history.push({ pathname: props.link, state: { data: props.options.data || null }})}
        >
          {props.options.name}
        </Button>
      );
      break;
    case "updateBtn":
      customBtn = (
        <span>
          {props.options.data}
        </span>
      );
      break;  
    default:
      return <></>;
  }
  return customBtn;
};
